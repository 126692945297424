<template>
    <div>
        <LandHeader />
        <BreadCrumb />
        <div class="center land-box land-info">
            <el-card class="box-card" shadow="never">
                <div slot="header" class="clearfix">
                    <span>
                        <span style="color:#333;font-size:16px;font-weight: bold;">资源编号：</span>
                        <span style="color:#1e88fb;font-size:16px;font-weight: bold;">{{ auctionInfo.resourceNo }}</span>
                    </span>
                     <el-text style="float: right; padding: 3px 0; color: gray" type="text"  class="el-icon-bell">竞价<span style="margin: 0px 3px;color: darkgreen;font-weight: bold">{{ auctionInfo.biddingCount }}</span>次</el-text>
                     <el-text style="float: right; padding: 3px 0;color: gray; margin-right: 20px" type="text"  class="el-icon-user">参与<span style="margin: 0px 3px;color: darkgreen;font-weight: bold">{{ auctionInfo.buyerCount }}</span>人</el-text>
                     <el-text style="float: right; padding: 3px 0;color: gray; margin-right: 20px" type="text" v-if="false"  class="el-icon-view">围观<span style="margin: 0px 3px;color: darkgreen;font-weight: bold">{{ auctionInfo.viewCount }}</span>次</el-text>
                </div>
                <el-progress :text-inside="true"  :stroke-width="24" :percentage="processPercentage" status="success"></el-progress>
                <div style="margin-top: 30px;">
                  <el-row :gutter="30" v-if="!isAuctioning && auctionInfo.transactionStatus !== 'finish'">
                        <el-col :span="8">
                            <el-card class="box-card"  shadow="never" style="text-align: center;">
                                <div slot="header" class="clearfix">
                                    <span>
                                        公示截止时间
                                    </span>
                                </div>
                                <div style="color:#1e88fb;font-size: 24px;">
                                    {{ dateStr(auctionInfo.applyEndTime)}}
                                </div>
                            </el-card>
                        </el-col>
                        <el-col :span="8">
                            <el-card class="box-card" shadow="never" style="text-align: center;">
                                <div slot="header" class="clearfix">
                                    <span>
                                        竞买保证金（RMB）
                                    </span>
                                </div>
                                <div style="color:#1e88fb;font-size: 24px;">
                                    {{ auctionInfo.deposit }} 万元
                                </div>
                            </el-card>
                        </el-col>
                        <el-col :span="8">
                            <el-card class="box-card" shadow="never" style="text-align: center;">
                                <div slot="header" class="clearfix">
                                    <span>
                                        挂牌开始时间
                                    </span>
                                </div>
                                <div style="color:#1e88fb;font-size: 24px;">
                                    {{ dateStr(auctionInfo.auctionStartTime)}}
                                </div>
                            </el-card>
                        </el-col>
                    </el-row>
                  <el-row :gutter="30" v-else-if="auctionInfo.transactionStatus === 'finish'">
                    <el-col :span="8">
                      <el-card class="box-card"  shadow="never" style="text-align: center;">
                        <div slot="header" class="clearfix">
                                    <span>
                                        挂牌截止时间
                                    </span>
                        </div>
                        <div style="color:#1e88fb;font-size: 24px;">
                          {{ dateStr(auctionInfo.auctionEndTime)}}
                        </div>
                      </el-card>
                    </el-col>
                    <el-col :span="8">
                      <el-card class="box-card" shadow="never" style="text-align: center;">
                        <div slot="header" class="clearfix">
                                    <span>
                                        成交价格（RMB）
                                    </span>
                        </div>
                        <div style="color:#1e88fb;font-size: 24px;">
                          {{ auctionInfo.currentPrice }} 万元
                        </div>
                      </el-card>
                    </el-col>
                    <el-col :span="8">
                      <el-card class="box-card" shadow="never" style="text-align: center;">
                        <div slot="header" class="clearfix">
                                    <span>
                                        倒计时
                                    </span>
                        </div>
                        <div style="color:#1e88fb;font-size: 24px;">
                          已截止
                        </div>
                      </el-card>
                    </el-col>
                  </el-row>
                  <el-row :gutter="30" v-else>
                    <el-col :span="8">
                      <el-card class="box-card"  shadow="never" style="text-align: center;">
                        <div slot="header" class="clearfix">
                                    <span>
                                        挂牌截止时间
                                    </span>
                        </div>
                        <div style="color:#1e88fb;font-size: 24px;">
                          {{ dateStr(auctionInfo.auctionEndTime)}}
                        </div>
                      </el-card>
                    </el-col>
                    <el-col :span="6">
                      <el-card class="box-card" shadow="never" style="text-align: center;">
                        <div slot="header" class="clearfix">
                                    <span>
                                        当前价格（RMB）
                                    </span>
                        </div>
                        <div style="color:#1e88fb;font-size: 24px;">
                          {{ auctionInfo.currentPrice ?? auctionInfo.startPrice }} 万元
                        </div>
                      </el-card>
                    </el-col>
                    <el-col :span="10">
                      <el-card class="box-card" shadow="never" style="text-align: center;">
                        <div slot="header" class="clearfix">
                                    <span>
                                        倒计时
                                    </span>
                        </div>
                        <div style="color:#1e88fb;font-size: 24px;">
                          <timeCounter :endTime="auctionInfo.auctionEndTime" size="24px" endText="已经结束" />
                        </div>
                      </el-card>
                    </el-col>
                  </el-row>
                    <div style="text-align: center;padding:30px 0 0 20px;"  v-show="visible">
                        <el-button type="info" plain round style="width:233px"  v-if="auctionInfo.currentBuyerInfo && auctionInfo.currentBuyerInfo.auditStatus ===1">报名审核中</el-button>

                        <el-button  type="danger" plain round style="width:233px"  v-if="!isAuctioning && auctionInfo.currentBuyerInfo && auctionInfo.currentBuyerInfo.auditStatus ===2">报名成功</el-button>
                        <el-button  type="danger" plain round style="width:233px"   v-if="isApply && auctionInfo.currentBuyerInfo && auctionInfo.currentBuyerInfo.auditStatus ===3" @click="applyTrade">再次报名</el-button>
                        <el-button  type="danger" v-if="auctionInfo.biddingStatus ===3 && isAuctioning "  plain round style="  width:233px" @click="goToAdmin">参与竞价</el-button>
                        <el-button  type="danger" v-if="auctionInfo.biddingStatus ===1 && isApply"  plain round style="width:233px" @click="applyTrade">报名竞价</el-button>
                    </div>
                  <div  style="text-align: center;padding:30px 0 0 20px;"  v-if="auctionInfo.transactionStatus === 'finish'">
                    <el-button type="info" plain round style="width:233px"  >已成交</el-button>
                  </div>

                  <div  style="text-align: center;padding:30px 0 0 20px;"  v-if="auctionInfo.biddingStatus ===1 && !isApply && !visible && auctionInfo.transactionStatus != 'finish' ||
                   auctionInfo.biddingStatus ===3 && !isAuctioning && auctionInfo.transactionStatus != 'finish' && !visible">
                    <el-button type="info" plain round style="width:233px"  >竞价已结束</el-button>
                  </div>
                </div>
            </el-card>

            <div class="land-info__detail">
                <div class="land-info__detail__nav">
                    <a @click="scrollInto('info')">土地信息</a>
                    <a @click="scrollInto('prices')" >出价记录</a>
                    <a @click="scrollInto('notice')" >转让信息</a>
                    <a @click="scrollInto('img')" >土地图片</a>
                    <a @click="scrollInto('map')" >土地地图</a>
                  <a v-if="landInfo.vr" @click="gotoLink(landInfo.vr)" >VR</a>
                </div>
                <div id="info">
                    <el-descriptions title="土地信息" :column="2" border>
                        <el-descriptions-item label="土地名称" label-align="right" align="center" width="150px" span="2">{{ landInfo.title }}</el-descriptions-item>
                        <el-descriptions-item label="资源编号" label-align="right" align="center" width="150px" span="2">{{ auctionInfo.resourceNo }}</el-descriptions-item>
                        <el-descriptions-item label="报名时间开始" label-align="right" align="center" width="150px">{{ dateStr(auctionInfo.applyStartTime)}}</el-descriptions-item>
                        <el-descriptions-item label="报名时间截止" label-align="right" align="center" width="150px">{{ dateStr(auctionInfo.applyEndTime)}}</el-descriptions-item>
                        <el-descriptions-item label="挂牌时间开始" label-align="right" align="center" width="150px">{{ dateStr(auctionInfo.auctionStartTime)}}</el-descriptions-item>
                        <el-descriptions-item label="挂牌时间截止" label-align="right" align="center" width="150px">{{ dateStr(auctionInfo.auctionEndTime)}}</el-descriptions-item>
<!--                        <el-descriptions-item label="保证金缴纳时间开始" label-align="right" align="center" width="150px">{{ dateStr(auctionInfo.depositStartTime)}}</el-descriptions-item>-->
<!--                        <el-descriptions-item label="保证金缴纳时间截止" label-align="right" align="center" width="150px">{{ dateStr(auctionInfo.depositEndTime)}}</el-descriptions-item>-->
                        <el-descriptions-item label="竞买保证金（RMB）" label-align="right" align="center" width="150px">{{ auctionInfo.deposit }} 万元</el-descriptions-item>
                        <el-descriptions-item label="起始价" label-align="right" align="center" width="150px">{{ auctionInfo.startPrice }} 万元</el-descriptions-item>
                        <el-descriptions-item label="竞价增价幅度" label-align="right" align="center" width="150px">{{ auctionInfo.rise }} 万元</el-descriptions-item>


                      <el-descriptions-item label="土地用途" label-align="right" align="center" width="150px">{{ findLabel(demand_purpose,landInfo.landPurpose) }}
                        {{landInfo.landPurpose=='other_land'?' - '+landInfo.subLandPurpose:''}}

                      </el-descriptions-item>
                      <el-descriptions-item label="交易方式" label-align="right" align="center" width="150px">{{ findLabel(transaction_way,landInfo.transactionWay) }}</el-descriptions-item>

                      <el-descriptions-item label="所属行政区" label-align="right" align="center">{{ findLabel(land_area,landInfo.regionId) }}</el-descriptions-item>
<!--                        <el-descriptions-item label="权属人性质" label-align="right" align="center">{{ findLabel(land_owner_type,landInfo.ownerType) }}</el-descriptions-item>-->
                        <el-descriptions-item label="土地权属单位" label-align="right" align="center">{{landInfo.ownerOrg}}</el-descriptions-item>
                        <el-descriptions-item label="权证类型" label-align="right" align="center">{{findLabel(land_license_type,landInfo.licenseNoType)}}</el-descriptions-item>
<!--                        <el-descriptions-item label="权证编号" label-align="right" align="center">{{landInfo.licenseNo}}</el-descriptions-item>-->
                        <el-descriptions-item label="土地面积" label-align="right" align="center">{{landInfo.landSize}}平方米</el-descriptions-item>
                        <el-descriptions-item label="使用权截止日期" label-align="right" align="center">{{landInfo.landDeadLine}}</el-descriptions-item>
                        <el-descriptions-item label="土地位置" label-align="right" align="center">{{landInfo.landPosition}}</el-descriptions-item>
                      <el-descriptions-item v-if="landInfo.hasGroundBuilding" label="建筑类型" label-align="right" align="center">{{landInfo.buildingType}}</el-descriptions-item>
                      <el-descriptions-item v-if="landInfo.hasGroundBuilding" label="建筑面积" label-align="right" align="center">{{landInfo.buildingSize}}平方米</el-descriptions-item>
                      <el-descriptions-item label="土地描述" span="3"><div v-html="landInfo.desc"></div></el-descriptions-item>
                    </el-descriptions>
                </div>
                <div id="prices">
                    <el-descriptions title="出价记录" :column="1"></el-descriptions>
                  <el-table
                      border
                      :data="pricesData"
                      style="width: 100%">
                    <el-table-column
                        type="index"
                        label="序号"
                        width="150">
                    </el-table-column>
                    <el-table-column
                        label="出价人"
                        width="200">
                      <template #default="scope">
                        {{ getUserName(scope.row.buyerId) }}
                      </template>
                    </el-table-column>
                    <el-table-column
                        prop="biddingPrice"
                        label="出价金额">
                      <template #default="scope">
                        {{ scope.row.biddingPrice }}万元
                      </template>
                    </el-table-column>
                    <el-table-column
                        prop="applyTime"
                        label="出价时间">
                      <template #default="scope">
                        {{ dateStr(scope.row.applyTime) }}
                      </template>
                    </el-table-column>
<!--                    <el-table-column  prop="applyTime"  label="当前状态" v-if="auctionInfo.auctionStatus !== 2">-->
                    <el-table-column  prop="applyTime"  label="当前状态" >
                      <template #default="scope">
                        <span style="color:deepskyblue" v-if="scope.$index === 0 ">最高价</span>
                      </template>
                    </el-table-column>

                    <el-table-column
                        label="中标" v-if="auctionInfo.auctionStatus === 99">
                      <template #default="scope">
                        <el-tag class="ml-2" type="success" v-if="scope.row.isSelected === 1">中标</el-tag>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
                <div id="notice">
                    <el-descriptions title="转让信息" :column="1"></el-descriptions>
                    <el-tabs type="border-card" v-model="noticeTab">
                        <el-tab-pane label="公示公告" name="sell_notice">
                            <div>
                                <p  v-html="notice.transferAnnouncement">
                                </p>
                                <el-empty :image-size="100" v-if="!notice.transferAnnouncement" description="暂无内容"></el-empty>
                            </div>
                        </el-tab-pane>
                        <el-tab-pane label="补充公告" name="replenish_notice">
                            <div>
                                <p  v-html="notice.afterAnnouncement">
                                </p>
                                <el-empty :image-size="100" v-if="!notice.afterAnnouncement" description="暂无内容"></el-empty>
                            </div>
                        </el-tab-pane>
                        <el-tab-pane label="转让须知" name="third">
                            <div>
                                <p  v-html="notice.transferNotice">
                                </p>
                                <el-empty :image-size="100" v-if="!notice.transferNotice" description="暂无内容"></el-empty>
                            </div>
                        </el-tab-pane>
                        <el-tab-pane label="转让规则" name="fourt1h">
                            <div>
                                <p  v-html="notice.transferRule">
                                </p>
                                <el-empty :image-size="100" v-if="!notice.transferRule" description="暂无内容"></el-empty>
                            </div>
                        </el-tab-pane>
                        <el-tab-pane label="竞买人条件" name="fourt2">
                            <div>
                                <p  v-html="notice.buyerCondition">
                                </p>
                                <el-empty :image-size="100" v-if="!notice.buyerCondition" description="暂无内容"></el-empty>
                            </div>
                        </el-tab-pane>
                        <el-tab-pane label="其他交易条件" name="fourth3">
                            <div>
                                <p  v-html="notice.otherCondition">
                                </p>
                                <el-empty :image-size="100" v-if="!notice.otherCondition" description="暂无内容"></el-empty>
                            </div>
                        </el-tab-pane>
                    </el-tabs>
                </div>

                <div id="img">
                    <el-descriptions title="土地图片" :column="1"></el-descriptions>
                    <el-row :gutter="20">
                        <el-col :span="12" v-for="(item,index) in landPics" :key="index">
                            <img :src="item" width="100%"/>
                        </el-col>
                    </el-row>
                </div>
                <div id="map">
                    <el-descriptions title="土地地图" :column="1"></el-descriptions>
                    <Map :landInfo="landInfo"/>
                </div>
            </div>
        </div>
        <footer2 />
        <AgreementTradeFiles ref="tradeFiles" />
      <FaceRecognition ref="facerecognitionRef"   />
<!--        <agreement-trade-files  ref=""></agreement-trade-files>-->
    </div>
</template>
<script >
import LandHeader from "@/components/nav";
import ElementUI from "element-ui";
import footer2 from "components/footer.vue";
import Map from "@/components/TMap.vue";
import CommonService, {getidCardInfo} from "../service/CommonService";
import { useDict,findLabel } from "@/hooks/dict";
import dayjs from "dayjs";
import '@/assets/css/land-info.scss'
import {Message, MessageBox} from "element-ui";
import AgreementTradeFiles from "@/components/AuctionTradeFiles.vue";
import timeCounter from "@/components/TimeCounter.vue";
import FaceRecognition from "./my/FaceRecognition.vue";

export default {
    name: "LandInfoTrade",
    components: {
      timeCounter,
        LandHeader,
      FaceRecognition,
        AgreementTradeFiles,
        footer2,
        Map
    },
    data() {
        return {
            noticeTab:'sell_notice',
            timer:null,
          timernew:null,
            newTimer:null,
            endTimer:null,
            edit:{
              visible:false,
            },
            pricesData :[],
            auctionInfo: {
                id: '',
                landId: '',
              biddingCount: '',
                biddingStatus: 1,
                resourceNo: "",
                stage: "",
                currentPrice: '',
                buyerCount: '',
                startPrice: '',
                applyStartTime: '',
                applyEndTime: '',
                dealPrice: '',
                deposit: '',
                depositStartTime: '',
                depositEndTime: '',
                transferTimeLimit: "",
                rise: '',
                assessedPrice: '',
                reservePrice: '',
                priorityPurchaser: '',
                freeBiddingTime: '',
                limitedBiddingTime: '',
                registrationLimit: '',
                location: '',
                softwareUsageFee: '',
                commissionRate: '',
                importantNotice: "",
                auctionNotice: "",
                biddingInstructions: "",
                subjectIntroduction: "",
                auctionStatus: 0,
                transactionStatus: '',
                isPublished: '',
                auctionStartTime: '',
                auctionEndTime: '',
              viewCount: '',
            },
            landInfo: {
                id: "",
                title: "",
                landPics: '',
                regionId: '',
                createTime: '',
                price: '',
                landPurpose: '',
                transactionWay: '',
                ownerType: '',
                ownerOrg: '',
                licenseNoType: '',
                licenseNo: '',
                landSize: '',
                landDeadLine: '',
                landPosition: '',
                volumeRate: '',
            },
            notice: {
                transferAnnouncement: "",
				afterAnnouncement: "",
				transferNotice: "",
				transferRule: "",
				buyerCondition: "",
				otherCondition: "",
            },
        };
    },
    computed: {
        isApply(){
          let date = new Date()
          let tmp = JSON.parse(JSON.stringify(this.auctionInfo));
          return tmp.applyStartTime < date.getTime() && tmp.applyEndTime > date.getTime() &&
              tmp.transactionStatus !== 'finish';
        },
        isAuctioning(){
          let date = new Date()
          let tmp = JSON.parse(JSON.stringify(this.auctionInfo));
          console.log(111)
          console.log( date.getTime())
          console.log( tmp.auctionStartTime )
          return tmp.auctionStartTime < date.getTime() && tmp.auctionEndTime > date.getTime() &&
              tmp.transactionStatus !== 'finish';
        },
         visible(){
          let date = new Date()
           let tmp = JSON.parse(JSON.stringify(this.auctionInfo));
          return tmp.auctionEndTime > date.getTime() && tmp.transactionStatus !== 'finish';
        },
        processPercentage(){
          if ( this.auctionInfo.transactionStatus === 'finish') {
            return  100;
          }
          if (this.isAuctioning) {
            return  66;
          }
          return 33;
        },
        landPics() {
            return this.landInfo.landPics.split(",");
        },
        land_purpose() {
            return this.getDict("land_purpose" );
        },
        transaction_way() {
            return this.getDict("transaction_way" );
        },
        land_area() {
            return this.getDict("land_area" );
        },
        land_owner_type() {
            return this.getDict("land_owner_type" );
        },
        land_license_type() {
            return this.getDict("land_license_type" );
        },
      demand_purpose() {
        // console.log(this.getDict("demand_purpose"))
        return this.getDict("demand_purpose");
      },
    },
    mounted() {
        this.loadAuctionInfo();
    },
    beforeDestroy() {
      if (this.timer) {
        clearInterval(this.timer);
      }
      if (this.newTimer) {
        clearInterval(this.newTimer);
      }
      if (this.endTimer) {
        clearInterval(this.endTimer);
      }
    },
    methods: {
      lunxunData ()  {


        if( this.timernew ==null)
        {
          this.timernew = window.setInterval(() => {
            setTimeout(() => {
              this.checkQrCode()
            }, 0)
          }, 3000)

        }

      },
      async  checkQrCode()  {
        const res = await getidCardInfo();
        // window.console.log(res)
        if (res.data.checked === 1) {
          // 803表示登录成功，以下做登录成功后的事件

          this.stop()

        } else if (res.data.code === 800) {
          // 803表示二维码过期
          window.console.log('二维码过期')
          // 开启css覆盖当前二维码
          // 停止轮询
          this.stop()
        }
      },
      stop(){
        window.clearInterval(this.timernew)
        clearInterval(this.timernew);
        this.timernew = null;
        window.location.reload();
      },
        getLandPurposeItemName(demand_purpose,id) {
          let land_purposes = useDict(demand_purpose);
          let tmp = '';
          land_purposes[demand_purpose].value.forEach((item) => {
            if (item.value === id) {
              tmp =  item.label;
            }
          })
          return tmp;
        },
        getUserName(userId) {
          return userId === localStorage.getItem('user_id') ? localStorage.getItem('username') :'*****';
        },
        scrollInto(id) {
          document.getElementById(id).scrollIntoView();
        },
        gotoLink(url) {
          this.$router.push("/link?url="+url);
        },
        loadAuctionInfo() {
            CommonService.getAuctionDetail(this.$route.params.id).then((res) => {
                if (res.code === 0) {
                    Object.assign(this.auctionInfo, res.data);
                    this.loadLandInfo(this.auctionInfo.landId);
                    this.loadNotice(this.auctionInfo.id);
                    this.loadPrices(this.auctionInfo.id);
                  if (this.isAuctioning) {
                    this.timer = setInterval(() => {
                      CommonService.getAuctionPrices({auctionId:this.auctionInfo.id}).then((priceRes) =>{
                        if (priceRes.code === 0 && priceRes.data.records.length > 0) {
                          this.auctionInfo.currentPrice = priceRes.data.records[0].biddingPrice;
                          this.pricesData = priceRes.data.records;
                        }
                      } );
                      let date = new Date().getTime();
                      if (this.auctionInfo.auctionEndTime < date) {
                        this.auctionInfo.biddingStatus = 2;
                      }
                    }, 3000)
                  }
                  if (this.isNormal) {
                    this.newTimer = setInterval(() => {
                      let date = new Date().getTime();
                      if (this.auctionInfo.applyEndTime < date) {
                        this.auctionInfo.biddingStatus = 2;
                      }
                    }, 5000)
                  }
                  if (this.isAuctioning) {
                    this.endTimer = setInterval(() => {
                      let date = new Date().getTime();
                      if ( date - this.auctionInfo.auctionEndTime < 5000 ) {
                        window.location.reload();
                      }
                    }, 30000)
                  }
                }
            });
        },
        loadLandInfo(landId) {
            CommonService.getLandDetail(landId).then((res) => {
                if (res.code === 0) {
                    Object.assign(this.landInfo, res.data);
                }
            });
        },
        loadNotice(actionId) {
            CommonService.getLandTransferInfoByAuctionId(actionId).then((res) => {
                if (res.code === 0) {
                    Object.assign(this.notice, res.data);
                }
            });
        },
        loadPrices(actionId){
            CommonService.getAuctionPrices({auctionId:actionId}).then((res) => {
                if (res.code === 0) {
                    this.pricesData = res.data.records;
                }
            });
        },
        getDict(dictName) {
            return useDict(dictName)[dictName].value;
        },
        findLabel,
        dateStr(date) {
            return dayjs(new Date(parseInt(date))).format("YYYY年MM月DD日 HH时mm分");
        },

      goToAdmin(){
        if(!CommonService.isLogin()){
          this.$message.error("请先登录");
          return;
        }

          let minPrice = parseInt((this.auctionInfo.currentPrice?? this.auctionInfo.startPrice)) + parseInt(this.auctionInfo.rise)
          this.$prompt('请输入竞拍价格且需不小于 ' + minPrice + ' (单位:万元)', '', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            inputPattern: /^[0-9.]*$/,
            inputErrorMessage: '请输入正确的价格'
          }).then(({ value }) => {
            if (value < minPrice) {
              this.$message.error("请输入不小于 " + minPrice + ' (万元)的竞拍价');
              return;
            }
            let tmp = (value - parseInt(this.auctionInfo.startPrice)) / this.auctionInfo.rise;
            if (!Number.isInteger(tmp)) {
              Message.error('出价需为加价幅度整数倍');
              return;
            }
            this.auctionInfo.currentPrice = Number(value);
            let params = {
                   biddingPrice:value,
                   auctionId:this.$route.params.id,
                   buyerId:localStorage.getItem('user_id')
            }
            try {
               CommonService.addAuctionPrice(params).then((res) => {
                 if (res.code === 0) {
                   Message.success('报价成功');
                 }else {
                   Message.error(res.msg);
                 }
               })
              setTimeout(function (){window.location.reload();},2000)
            } catch(err) {
              console.log(err)
              Message.error('报价失败');
              return;
            }
          }).catch(() => {
            this.$message({
              type: 'info',
              message: '取消输入'
            });
          });
        },
        applyTrade(){
            if(!CommonService.isLogin()){
              this.$message.error("请先登录");
              return;
            }

          CommonService.getUserInfo().then((re)=>{
            console.log(re.data.sysUser.name)
            if(re.data.sysUser.name==''  || re.data.sysUser.name==null)
            {
              this.lunxunData();
              this.$refs.facerecognitionRef.openDialog('','', (data) => {
                stop();
              });
            }else {
              this.$refs.tradeFiles.openDialog({id:this.$route.params.id},'listing');
            }

          });

          }
    },
};
</script>